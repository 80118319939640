import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'

import thumb01 from '../assets/images/thumbs/01.jpg'
import thumb02 from '../assets/images/thumbs/02.jpg'
import thumb03 from '../assets/images/thumbs/03.jpg'
import thumb04 from '../assets/images/thumbs/04.jpg'
import thumb05 from '../assets/images/thumbs/05.jpg'
import thumb06 from '../assets/images/thumbs/06.jpg'
import thumb07 from '../assets/images/thumbs/07.jpg'
import thumb08 from '../assets/images/thumbs/08.jpg'
import thumb09 from '../assets/images/thumbs/09.jpg'
import thumb10 from '../assets/images/thumbs/10.jpg'
import thumb11 from '../assets/images/thumbs/11.jpg'

// import full01 from '../assets/images/fulls/01.jpg'
// import full02 from '../assets/images/fulls/02.jpg'
import full03 from '../assets/images/fulls/03.jpg'
// import full04 from '../assets/images/fulls/04.jpg'
// import full05 from '../assets/images/fulls/05.jpg'
// import full06 from '../assets/images/fulls/06.jpg'
// import full07 from '../assets/images/fulls/07.jpg'
// import full08 from '../assets/images/fulls/08.jpg'
import full09 from '../assets/images/fulls/09.jpg'
// import full10 from '../assets/images/fulls/10.jpg'
// import full11 from '../assets/images/fulls/11.jpg'

const DEFAULT_IMAGES = [
    { id: '1', src: 'https://vimeo.com/245237744', thumbnail: thumb02, caption: 'IBM - Engage! Sales Enablement Platform', description: 'An IBM Sales Enablement Platform designed for IBM’s Sales Team to excel in ideation and collaboration among IBM Sellers.'},
		{ id: '2', src: 'https://www.arity.com/', thumbnail: thumb01, caption: 'Arity - Website', description: 'Arity, an Allstate company, is an insights technology company, combining data from connected cars and predictive analytics to help you evaluate risk and make smarter decisions.'},
		{ id: '3', src: 'http://isaiahpandrew.com/images/projects/ibmvideo.mp4', thumbnail: thumb04, caption: 'IBM - YourIBM', description: 'IBM’s W3 Intranet dashboard and central workspace for IBM employees. Consits of customizable widgets.'},
    { id: '4', src: full03, thumbnail: thumb03, caption: 'IBM - BluePages', description: 'IBM’s internal Employee Directory consisting of 350k+ active user profiles.'},
	{ id: '5', src: 'https://vimeo.com/99673958', thumbnail: thumb05, caption: 'CME Group - Website Animations & Quizes', description: 'Custom Javascript interactive animations and quizes showcasing CME’s Educational Website called Future Fundamentals.'},
    { id: '6', src: 'https://irrationallabs.org/', thumbnail: thumb06, caption: 'Irrational Labs - Website', description: 'Behavioral science to make people happier, healthier & wealthier. Website was developed with Wordpress and frontend technologies. HTML/CSS/JS'},
	{ id: '7', src: 'https://behavioraleconomicsbootcamp.com/', thumbnail: thumb11, caption: 'Behavioral Economics Bootcamp - Website', description: 'Behavioral Economics Bootcamp for product leaders. Website was developed with Wordpress and frontend technologies. HTML/CSS/JS'},
    { id: '8', src: 'https://www.reviewtrackers.com/', thumbnail: thumb10, caption: 'ReviewTrackers - Website', description: 'ReviewTrackers monitors online reviews & customer experience. Website was developed with Wordpress and frontend technologies. HTML/CSS/JS'},
    { id: '9', src: 'http://platformcoworking.com/', thumbnail: thumb07, caption: 'Platform CoWorking - Website', description: 'Platform is Chicago’s coworking OG, providing a modern approach to the daily grind. Website was developed with Wordpress and frontend technologies. HTML/CSS/JS'},
    { id: '10', src: 'https://spikeball.com/', thumbnail: thumb08, caption: 'Spikeball - Website & Ecommerce', description: 'Spikeball is a growing sport as seen on Shark Tank. Website was developed with Wordpress, Shopify, and frontend technologies. HTML/CSS/JS'},
    { id: '11', src: full09, thumbnail: thumb09, caption: 'TedxMidwest - Website', description: 'Website was developed with Wordpress and frontend technologies. HTML/CSS/JS'}
];

class HomeIndex extends React.Component {

    constructor() {
        super();

        this.state = {
            lightboxIsOpen: false,
            currentImage: 0,
        };

        this.closeLightbox = this.closeLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.handleClickImage = this.handleClickImage.bind(this);
    }

    openLightbox (index, event) {
        event.preventDefault();
        this.setState({
            currentImage: index,
            lightboxIsOpen: true,
        });
    }
    closeLightbox () {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }
    gotoPrevious () {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }
    gotoNext () {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }
    handleClickImage () {
        if (this.state.currentImage === this.props.images.length - 1) return;

        this.gotoNext();
    }

    render() {
        const siteTitle = "Ryan Powszok | Full Stack Javascript Engineer"
        const siteDescription = "Ryan Powszok is a Full Stack Javascript Engineer living in Chicago"

        return (
            <Layout>
                <Helmet>
                        <title>{siteTitle}</title>
                        <meta name="description" content={siteDescription} />
                </Helmet>

                <div id="main">

                    <section id="one">
                        <header className="major">
                            <h2>Hi, I'm Ryan.</h2>
                        </header>
                        <p>I'm a Full Stack Javascript Engineer.</p>
                        {/*
												<ul className="actions">
                            <li><a href="#" className="button">Learn More</a></li>
                        </ul>
												*/}
                    </section>

                    <section id="two">
                        <h2>Recent Work</h2>

                        <Gallery images={DEFAULT_IMAGES.map(({ id, src, thumbnail, caption, description }) => ({
                            src,
                            thumbnail,
                            caption,
                            description
                        }))} />

                        {/*
												<ul className="actions">
                            <li><a href="#" className="button">Full Portfolio</a></li>
                        </ul>
												*/}
                    </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex
